const cacheDuration = [
    {
        url: 'getAppVersion',
        refreshAfter: 1 // in minutes
    },
    {
        url: 'farmer/getall',
        refreshAfter: 1 // in minutes
    },
    {
        url: 'getFarmerById',
        refreshAfter: 2 // in minutes
    },
    {
        url: 'allFarmers',
        refreshAfter: 3 // in minutes
    },
]

function sendFarmerStagingData() {
    if (idbApp) {
        var farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
            return obj;
        });
        farmertmpobj.then((farmertmpobj) => {
            if (farmertmpobj.length > 0) {
                const farmerobj = JSON.stringify(farmertmpobj);
                let currentUser; 
                let fp; 
                let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
        
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
        
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                        return obj;
                    });
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                            return obj;
                        });
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                            apiBaseUrl = apiBaseUrlObj[0]['url'];
                            if (currentUser && currentUser.token) {
        
                                let headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`
                                };
                                if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                
                                headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`,
                                    "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') !== '0') ? localStorage.getItem('ecosystem_id') : '0'
                                };
                                }
        
                                fetch(apiBaseUrl + "/api/farmerstaging/saveFarmerStagingData", {
                                    method: 'post',
                                    headers: headersToSet,
                                    body: farmerobj
                                })
                                .then(function(response) {
                                    return response.json();
                                })
                                .then(function (data) {
                                })
                                .catch(function (error) {
                                });
                            }
                        })
                    })
                });
            }
        });
    }
}

function syncFarmerPhotoData(){
    if (idbAppPhoto) {
        var farmertmpobj = (idbAppPhoto.getAllProjects('farmersPhototmp')).then(function (obj) {
            return obj[0];
        });
        farmertmpobj.then(function(farmertmpobj){
            // console.log("syncFarmerPhotoData",farmertmpobj);
            if (farmertmpobj && farmertmpobj.tmp_id) {
                // farmertmpobj.farmerImageData = new Uint16Array(farmertmpobj.farmerImageData);
                const farmerobj = JSON.stringify(farmertmpobj);
                let currentUser; let fp; let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                        return obj;
                    }); 
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                            return obj;
                        });
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                            apiBaseUrl = apiBaseUrlObj[0]['url'];
                            if (currentUser && currentUser.token) {
                                 let headersToSet = {
                                        'Accept': 'application/json, text/plain, */*',
                                        "Content-type": "application/json",
                                        "Authorization": `${currentUser.token}`,
                                        "fp" : `${fp}`
                                    };
                                    if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                        // console.log('ecosystem_id', localStorage.getItem('ecosystem_id'));
                                        headersToSet = {
                                            'Accept': 'application/json, text/plain, */*',
                                            "Content-type": "application/json",
                                            "Authorization": `${currentUser.token}`,
                                            "fp" : `${fp}`,
                                            "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                                        };
                                    }
                                    fetch(apiBaseUrl + "/api/farmerstaging/saveFarmerPhotoInStaging", {
                                        method: 'post',
                                        headers: headersToSet,
                                        body: farmerobj
                                      })
                                      .then(function(response) {
                                        // console.log('response',response);
                                        return response.json();
                                      }).then(function(tobedeleted){
                                        // console.log('tobedeleted',tobedeleted);
                                        if(tobedeleted && tobedeleted.tmp_id){
                                            idbAppPhoto.removeDataFromStore('farmersPhototmp', tobedeleted.tmp_id);
                                            sendSyncEvent();
                                        }
                                      }).catch(function (error) {});
                            }
                        });
                    });
                });
            }
        }).catch(function (error) {
        });   
    }
}

//added by KRPT for signature syncing

function syncFarmerSignatureData(){
    if (idbAppPhoto) {
        var farmertmpsignobj = (idbAppPhoto.getAllProjects('farmerSignaturetmp')).then(function (obj) {
            return obj[0];
        });
        farmertmpsignobj.then(function(farmertmpsignobj){
            // console.log("syncFarmerPhotoData",farmertmpsignobj);
            if (farmertmpsignobj && farmertmpsignobj.tmp_id) {
                // farmertmpsignobj.farmerImageData = new Uint16Array(farmertmpsignobj.farmerImageData);
                const farmersignobj = JSON.stringify(farmertmpsignobj);
                let currentUser; let fp; let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                        return obj;
                    }); 
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                            return obj;
                        });
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                            apiBaseUrl = apiBaseUrlObj[0]['url'];
                            if (currentUser && currentUser.token) {
                                 let headersToSet = {
                                        'Accept': 'application/json, text/plain, */*',
                                        "Content-type": "application/json",
                                        "Authorization": `${currentUser.token}`,
                                        "fp" : `${fp}`
                                    };
                                    if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                        // console.log('ecosystem_id', localStorage.getItem('ecosystem_id'));
                                        headersToSet = {
                                            'Accept': 'application/json, text/plain, */*',
                                            "Content-type": "application/json",
                                            "Authorization": `${currentUser.token}`,
                                            "fp" : `${fp}`,
                                            "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                                        };
                                    }
                                    fetch(apiBaseUrl + "/api/farmerstaging/saveFarmerPhotoInStaging", {
                                        method: 'post',
                                        headers: headersToSet,
                                        body: farmersignobj
                                      })
                                      .then(function(response) {
                                        // console.log('response',response);
                                        return response.json();
                                      }).then(function(tobedeleted){
                                        // console.log('tobedeleted',tobedeleted);
                                        if(tobedeleted && tobedeleted.tmp_id){
                                            idbAppPhoto.removeDataFromStore('farmerSignaturetmp', tobedeleted.tmp_id);
                                            sendSyncEvent();
                                        }
                                      }).catch(function (error) {});
                            }
                        });
                    });
                });
            }
        }).catch(function (error) {
        });   
    }
}

//added by SBJY for farm data syncing
function syncFarmData() {
    if (idbAppPhoto) {
        var farmtmpsobj = (idbAppPhoto.getAllProjects('farmCoordinatestmp')).then(function (obj) {
            return obj[0];
        });
        farmtmpsobj.then(function (farmtmpsobj) {
            if (farmtmpsobj && farmtmpsobj.tmp_id) {
                const farmdataobj = JSON.stringify(farmtmpsobj);
                let currentUser; let fp; let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                        return obj;
                    });
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                            return obj;
                        });
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                            apiBaseUrl = apiBaseUrlObj[0]['url'];
                            if (currentUser && currentUser.token) {
                                let headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp": `${fp}`
                                };
                                if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                    headersToSet = {
                                        'Accept': 'application/json, text/plain, */*',
                                        "Content-type": "application/json",
                                        "Authorization": `${currentUser.token}`,
                                        "fp": `${fp}`,
                                        "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                                    };
                                }
                                fetch(apiBaseUrl + "/api/farmerstaging/saveFarmDataInStaging", {
                                    method: 'post',
                                    headers: headersToSet,
                                    body: farmdataobj
                                })
                                    .then(function (response) {
                                        return response.json();
                                    }).then(function (tobedeleted) {
                                        // if (tobedeleted && tobedeleted.tmp_id) {
                                        //     idbAppPhoto.removeDataFromStore('farmCoordinatestmp', tobedeleted.tmp_id);
                                            // sendSyncEvent();
                                        // }
                                    }).catch(function (error) { });
                            }
                        });
                    });
                });
            }
        }).catch(function (error) {
        });
    }
}

function getProcessedFarmData() {
    if (idbApp && idbAppPhoto) {
        var farmtmpobj = (idbAppPhoto.getAllProjects('farmCoordinatestmp')).then(function (obj) {
            return obj;
        });
        farmtmpobj.then((farmtmpobj) => {
            if (farmtmpobj.length > 0) {
                let uniqueIdsArray = []
                farmtmpobj.map((item) => {
                    uniqueIdsArray.push(item.tmp_id + "_" + item.randnum)
                }); 
                
                let currentUser; 
                let fp; 
                let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
        
                const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                    return obj;
                });
                fpObj.then(fpObj => {
                    fp = fpObj[0]['fp'];
        
                    const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                        return obj;
                    });
                    apiBaseUrlObj.then(apiBaseUrlObj => {
                        apiBaseUrl = apiBaseUrlObj[0]['url'];
                        if (currentUser && currentUser.token) {
        
                            let headersToSet = {
                                'Accept': 'application/json, text/plain, */*',
                                "Content-type": "application/json",
                                "Authorization": `${currentUser.token}`,
                                "fp" : `${fp}`
                            };
                            if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                            
                                headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`,
                                    "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') !== '0') ? localStorage.getItem('ecosystem_id') : '0'
                                };
                            }
        
                            fetch(apiBaseUrl + "/api/nq/checkprocessedFarmData", {
                                method: 'post',
                                headers: headersToSet,
                                body: JSON.stringify(uniqueIdsArray)
                            })
                            .then(function(response) {
                                return response.json();
                            })
                            .then(function (data) {
                                if (data.code == 'success') {
                                    if (data != null && data.message != null && data.message.length > 0) {
                                        for (let i = 0; i< data.message.length ; i++) {
                                            let objectStoreRequest = idbAppPhoto.removeDataFromStore('farmCoordinatestmp', data.message[i].tmp_id);
                                            
                                        }  
                                        sendSyncEvent();
                                    }
                                }
                            })
                            .catch(function (error) {
                            });
                        }
                    })
                })
        
                });
            }
        });
    }
}

function getProcessedData() {
    if (idbApp) {
        var farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
            return obj;
        });
        farmertmpobj.then((farmertmpobj) => {
            if (farmertmpobj.length > 0) {
        
                const farmerobj = JSON.stringify(farmertmpobj);
        
                let currentUser; 
                let fp; 
                let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
        
                const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {
                    return obj;
                });
                fpObj.then(fpObj => {
                    fp = fpObj[0]['fp'];
        
                    const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {
                        return obj;
                    });
                    apiBaseUrlObj.then(apiBaseUrlObj => {
                        apiBaseUrl = apiBaseUrlObj[0]['url'];
                        if (currentUser && currentUser.token) {
        
                            let headersToSet = {
                                'Accept': 'application/json, text/plain, */*',
                                "Content-type": "application/json",
                                "Authorization": `${currentUser.token}`,
                                "fp" : `${fp}`
                            };
                            if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                            
                                headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`,
                                    "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') !== '0') ? localStorage.getItem('ecosystem_id') : '0'
                                };
                            }
        
                            fetch(apiBaseUrl + "/api/farmerstaging/checkprocessed", {
                                method: 'post',
                                headers: headersToSet,
                                body: farmerobj
                            })
                            .then(function(response) {
                                return response.json();
                            })
                            .then(function (data) {
                                if (data.msg == 'success') {
                                    if (data != null && data.data != null && data.data.length > 0) {
                                        for (let i = 0; i< data.data.length ; i++) {
                                            let objectStoreRequest = idbApp.removeDataFromStore('farmerstmp', data.data[i]);
                                        }  
                                        sendSyncEvent();
                                    }
                                }
                            })
                            .catch(function (error) {
                            });
                        }
                    })
                })
        
                });
            }
        });
    }
}

function sendFarmerStagingDataNQ() {
    if (idbApp) {
        var farmertmpobj = (idbApp.getAllProjects('dq_profiling')).then(function (obj) {
            return obj;
        });
        farmertmpobj.then((farmertmpobj) => {
            if (farmertmpobj.length > 0) {
                const farmerobj = JSON.stringify(farmertmpobj);
                let currentUser; let fp; let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {
                    return obj;
                });
        
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {return obj;});
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {return obj;});
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                            apiBaseUrl = apiBaseUrlObj[0]['url'];
                            if (currentUser && currentUser.token) {
                                let headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`
                                };
                                if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                    headersToSet = {
                                        'Accept': 'application/json, text/plain, */*',
                                        "Content-type": "application/json",
                                        "Authorization": `${currentUser.token}`,
                                        "fp" : `${fp}`,
                                        "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                                    };
                                }
                                fetch(apiBaseUrl + "/api/nq/saveProfilingDataOffline", {
                                    method: 'post',
                                    headers: headersToSet,
                                    body: farmerobj
                                })
                                .then(function(response) {
                                    return response.json();
                                })
                                .then(function (data) {
                                })
                                .catch(function (error) {
                                });
                            }
                        })
                    })
                });
            }
            });
    }
}

function getProcessedDataNQ() {
    if (idbApp) {
        var farmertmpobj = (idbApp.getAllProjects('dq_profiling')).then(function (obj) {
            return obj;
        });
        farmertmpobj.then((farmertmpobj) => {
            if (farmertmpobj.length > 0) {
                let tmpIdsArray = [];
                for (let i = 0; i < farmertmpobj.length; i++) {
                    tmpIdsArray.push(farmertmpobj[i].unique_id);
                }
                let currentUser; let fp; let apiBaseUrl;
                const currentUserString = (idbApp.getAllProjects('currentUser')).then(function (obj) {return obj;});
                currentUserString.then(currentUserString => {
                    currentUser = JSON.parse(currentUserString[0]['data']);
                    const fpObj = (idbApp.getAllProjects('fp')).then(function (obj) {return obj;});
                    fpObj.then(fpObj => {
                        fp = fpObj[0]['fp'];
                        const apiBaseUrlObj = (idbApp.getAllProjects('apiBaseUrl')).then(function (obj) {return obj;});
                        apiBaseUrlObj.then(apiBaseUrlObj => {
                        apiBaseUrl = apiBaseUrlObj[0]['url'];
                        if (currentUser && currentUser.token) {
                            let headersToSet = {
                                'Accept': 'application/json, text/plain, */*',
                                "Content-type": "application/json",
                                "Authorization": `${currentUser.token}`,
                                "fp" : `${fp}`
                            };
                            if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
                                headersToSet = {
                                    'Accept': 'application/json, text/plain, */*',
                                    "Content-type": "application/json",
                                    "Authorization": `${currentUser.token}`,
                                    "fp" : `${fp}`,
                                    "ecosystem_id": (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                                };
                            }
    
                            fetch(apiBaseUrl + "/api/nq/checkprocessedNQ", {
                                method: 'post',
                                headers: headersToSet,
                                body: JSON.stringify(tmpIdsArray)
                            })
                            .then(function(response) {
                                return response.json();
                            })
                            .then(function (data) {
                                // console.log('checkprocessedNQ Request succeeded with JSON response', data);
                                if (data && data.code == 'success') {
                                    if (data.message.length > 0) {
                                        for (let i = 0; i< data.message.length ; i++) {
                                            let objectStoreRequest = idbApp.removeDataFromStore('dq_profiling', data.message[i].unique_id);
                                        }
                                        sendSyncEvent();
                                    }
                                }
                                })
                                .catch(function (error) {
                                });
                            }
                        })
                    })
                });
            }
        });   
    }
}

function bingo() {
}

function sendSyncEvent() {
    if (idbApp && idbAppPhoto) {
        idbApp.getAllData('farmerstmp')
        .then(response => {
            if (response && response.length === 0) {
                idbApp.getAllData('dq_profiling')
                .then(response2 => {
                    if (response2 && response2.length === 0) {
                        idbAppPhoto.getAllProjects('farmersPhototmp')
                        .then(response3 => {
                            if (response3 && response3.length === 0) {
                                idbAppPhoto.getAllProjects('farmerSignaturetmp')
                                .then(response4 => {
                                    if (response4 && response4.length === 0) {
                                        idbAppPhoto.getAllData('farmCoordinatestmp')
                                        .then(response5 => {
                                            if (response5 && response5.length === 0) {
                                                window.dispatchEvent(new Event('partial-synced'));
                                            }
                                        });
                                    }
                                }) 
                            }
                        })
                        .catch(error => {})
                    }
                })
                .catch(error => {})
            }
        })
        .catch(error => {})
    }
}